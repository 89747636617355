@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.location-tips span {
    font-weight: 500;
    font-family: Roboto, 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    /* font-size: 1rem; */
    opacity: 0;

    transition: opacity 0.5s;   
}

.location-tips .visible {
    opacity: 1;
}