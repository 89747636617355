/* Sidebar.css */

.sidebar {
  position: absolute;
  left: 0;
  top: 0;
  height: 100vh;
  width: 400px;
  /* Adjust width as needed */
  background-color: #fff;
  border-right: 1px solid #ddd;
  transition: transform 0.3s ease;
  transform: translateX(-100%);

  display: flex;
  flex-direction: column;

  /* justify-content: center; */
  align-items: center;
}

.sidebar.open {
  transform: translateX(0);
}

.toggle-button {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #f0f0f0;
  border: none;
  cursor: pointer;
}

.menu-items {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.menu-items li {
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.menu-items li:last-child {
  border-bottom: none;
}

.search-bar {
  padding: 20px;
}

.stack-item {
  margin-bottom: 10px;
}

.stack-item button {
  background: transparent;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  cursor: pointer;
}

.stack-item .item-info {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.stack-item .item-info span {
  margin-left: 5px;
}

.organizerInfo {
  display: flex;
  align-items: center;
}

.organizerInfo .orgPfp {
  width: 50px;
  height: 50px;
  margin-right: 7px;
  border-radius: 50%;
}