@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

body {
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, "Helvetica Neue", Arial, sans-serif;;
}

.app-container {
  display: flex;
  height: 100vh;
}

.map-container {
  flex: 1;
  position: relative; /* Ensure relative positioning for absolute positioning of sidebar */
}

/* .toggle-button {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 999; Set a high z-index to ensure the button is above the sidebar */
/* } */

.sidebar {
  position: absolute;
  left: 0;
  top: 0;
  height: 100vh;
  width: 200px; /* Adjust width as needed */
  background-color: #fff;
  border-right: 1px solid #ddd;
  transition: transform 0.3s ease;
  transform: translateX(-100%);
  z-index: 998; /* Set z-index lower than toggle button to ensure button is clickable */
}

.sidebar.open {
  transform: translateX(0);
}




.leaflet-marker-icon, .leaflet-shadow-pane {
  display: none!important;
}

.location-tips {
  background: transparent!important;
  border: none!important;
  box-shadow: none!important;

  font-weight: bold;
}