.reviewScreen {
    position: absolute;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.428);
    z-index: 999;
}

.reviewPopUp {
    position: absolute;
    width: 400px;
    height: 200px;
    background-color: white;

    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);

    border-radius: 1rem;
}

.reviewPadding {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
}

.reviewPadding h1 {
    font-size: 1.3rem;
    font-weight: 500;
    text-align: center;
}

.reviewPadding button {
    margin-top: 10px;
    padding: 8px 13px;
    border-radius: 10px;
    border: 1px solid #ddd;
    background-color: #fff;

    cursor: pointer;
}

.reviewPadding .buttons button {
    color: rgb(32,33,36);
    font-weight: bold;
}

.reviewPadding .buttons button:nth-child(1) {
    margin-right: 5px;
}

.reviewPadding .buttons .submitBtn {
    background-color: rgb(99, 164, 248);
    
}

.reviewPadding .buttons .disabled {
    background-color: rgb(166, 177, 191);
    color: rgb(90, 90, 90);
}